import React from 'react'
import {Link} from 'gatsby'
import {makeStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "40%",
    margin: 'auto',
    paddingBlock: '2rem',
    backgroundColor: theme.palette.common.grey
  },
  header: {
    ...theme.typography.header,
    fontSize: '4rem',
    color: theme.palette.common.white,
    marginBlockEnd: '1rem' 
  },
  subheader: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    marginBlockStart: '1rem',
    color: theme.palette.common.white 
  },
  button: {
    marginBlock: "1rem",
    color: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.secondary.main
    } 
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <h1 className={classes.header}>404</h1>
          </Grid>
          <Grid item>
            <h2 className={classes.subheader}>Page not found</h2>
          </Grid>
          <Grid item>
            <Button disableRipple className={classes.button} component={Link} to="/">Back to the Portfolio?</Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default NotFoundPage